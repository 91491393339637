.nf-multi-cell .nf-row::after {
  clear: both;
  content: "";
  display: block;
}
.nf-multi-cell .nf-cell {
  float: left;
  padding: 0 5px;
}
.nf-multi-cell .five-sixths,
.nf-multi-cell .four-sixths,
.nf-multi-cell .one-fourth,
.nf-multi-cell .one-half,
.nf-multi-cell .one-sixth,
.nf-multi-cell .one-third,
.nf-multi-cell .three-fourths,
.nf-multi-cell .three-sixths,
.nf-multi-cell .two-fourths,
.nf-multi-cell .two-sixths,
.nf-multi-cell .two-thirds {
  float: none;
  margin-left: 0;
  width: 100%;
}
.nf-multi-cell .five-sixths .inside,
.nf-multi-cell .four-sixths .inside,
.nf-multi-cell .one-fourth .inside,
.nf-multi-cell .one-half .inside,
.nf-multi-cell .one-sixth .inside,
.nf-multi-cell .one-third .inside,
.nf-multi-cell .three-fourths .inside,
.nf-multi-cell .three-sixths .inside,
.nf-multi-cell .two-fourths .inside,
.nf-multi-cell .two-sixths .inside,
.nf-multi-cell .two-thirds .inside {
  padding: 20px;
}
.nf-multi-cell .five-sixths::after,
.nf-multi-cell .four-sixths::after,
.nf-multi-cell .one-fourth::after,
.nf-multi-cell .one-half::after,
.nf-multi-cell .one-sixth::after,
.nf-multi-cell .one-third::after,
.nf-multi-cell .three-fourths::after,
.nf-multi-cell .three-sixths::after,
.nf-multi-cell .two-fourths::after,
.nf-multi-cell .two-sixths::after,
.nf-multi-cell .two-thirds::after {
  clear: both;
  content: "";
  display: block;
}

@media only screen and (max-width: 600px) {
  .nf-cell {
    width: 100% !important;
  }
}